.vertical-menu {
	background: $color-lightgrey;
	padding: 15px;
	margin: 20px 0;
	&__header {
		margin: 0 0 20px 0;
	}
	.item {
		color: #212428;
		display: block;
		padding: 10px 20px;
		font-size: 15px;
		&.active {
			color: $color-blue;
		}
		&:hover {
			background: $color-blue;
			color: $color-white;
		}
	}
}

#sylius-default-address {
	.column {
		--width: 6;
		@include media-breakpoint-down(sm) {
			--width: 12;
		}
	}
}

#sylius-payments,
#sylius-shipments {
	.message {
		background: inherit !important;
		box-shadow: none !important;
	}
	.label {
		border-radius: 30px;
	}
}
