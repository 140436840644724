.register-form {
	margin: auto;
	width: 100%;
	.column {
		padding: 10px;
	}
	.form {
		width: 100%;
		max-width: 500px;
		margin: auto;
	}
	h4:not(:first-of-type) {
		margin-top: 40px;
	}
}
