.icon {
	display: inline-block;
	vertical-align: middle;
	overflow: hidden;
	background-size: contain;
	background-repeat: no-repeat;
	&-search {
		background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 18.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 192.904 192.904' xml:space='preserve'%3E%3Cpath d='M190.707,180.101l-47.078-47.077c11.702-14.072,18.752-32.142,18.752-51.831C162.381,36.423,125.959,0,81.191,0 C36.422,0,0,36.423,0,81.193c0,44.767,36.422,81.187,81.191,81.187c19.688,0,37.759-7.049,51.831-18.751l47.079,47.078 c1.464,1.465,3.384,2.197,5.303,2.197c1.919,0,3.839-0.732,5.304-2.197C193.637,187.778,193.637,183.03,190.707,180.101z M15,81.193 C15,44.694,44.693,15,81.191,15c36.497,0,66.189,29.694,66.189,66.193c0,36.496-29.692,66.187-66.189,66.187 C44.693,147.38,15,117.689,15,81.193z'/%3E%3C/svg%3E%0A");
	}

	&-search-white {
		background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 18.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 192.904 192.904' style='enable-background:new 0 0 192.904 192.904;' xml:space='preserve'%3E%3Cpath d='M190.707,180.101l-47.078-47.077c11.702-14.072,18.752-32.142,18.752-51.831C162.381,36.423,125.959,0,81.191,0 C36.422,0,0,36.423,0,81.193c0,44.767,36.422,81.187,81.191,81.187c19.688,0,37.759-7.049,51.831-18.751l47.079,47.078 c1.464,1.465,3.384,2.197,5.303,2.197c1.919,0,3.839-0.732,5.304-2.197C193.637,187.778,193.637,183.03,190.707,180.101z M15,81.193 C15,44.694,44.693,15,81.191,15c36.497,0,66.189,29.694,66.189,66.193c0,36.496-29.692,66.187-66.189,66.187 C44.693,147.38,15,117.689,15,81.193z' fill='%23ffffff' /%3E%3C/svg%3E%0A");
	}

	&-cart {
		background-image: url("../img/icons/cart.svg");
	}

	&-user {
		background-image: url("../img/icons/user.svg");
	}

	&-CZ {
		background-image: url("../img/icons/dishwasher.svg");
	}

	&-CP {
		background-image: url("../img/icons/washing-machine.svg");
	}

	&-CK {
		background-image: url("../img/icons/cooker.svg");
	}

	&-CL {
		background-image: url("../img/icons/fridge.svg");
	}

	&-CO {
		background-image: url("../img/icons/vacuum.svg");
	}

	&-iron {
		background-image: url("../img/icons/iron.svg");
	}

	&-microwave {
		background-image: url("../img/icons/microwave.svg");
	}

	&-COK {
		background-image: url("../img/icons/hood.svg");
	}

	&-CB {
		background-image: url("../img/icons/boiler.svg");
	}

	&-CE {
		background-image: url("../img/icons/cup.svg");
	}

	&-mail {
		background-image: url("../img/icons/mail.svg");
	}

	&-dialogue {
		background-image: url("../img/icons/dialogue.svg");
	}

	&-facebook {
		background-image: url("../img/icons/facebook.svg");
	}
}
