.widget {
	padding: 20px;
	width: 100%;
	margin: 20px 0;
	border: 1px solid $color-lightgrey;
	&--grey {
		background: $color-lightgrey;
	}
	&__header {
		margin: 0 0 30px 0;
	}
	&__segment {
		padding: 10px 0;
	}
	&__divider {
		height: 1px;
		background: #d4d4d5;
		margin: 20px 0;
	}
	&__title {
		border-bottom: 1px solid #ccc;
		padding: 10px;
	}
	&__image {
		float: left;
		margin-right: 20px;
	}
	a.item {
		display: block;
		padding: 10px 15px;
		&.active {
			background: #1d81bc;
			color: #fff;
			font-weight: bold;
		}
	}
}

.column .widget {
	margin: 0;
}