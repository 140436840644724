.product {
  &__code {
    color: lighten($color-black, 50);

    strong {
      color: $color-black;
      font-weight: 500;
      margin: 0 0 0 10px;
    }
  }

  &__price {
    display: flex;
    align-items: baseline;
    justify-content: center;

    strong {
      font-size: 2em;
      font-weight: 500;
      margin: 0 0 0 10px;
    }
  }

  &__shipping-price {
    color: lighten($color-black, 50);
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-size: 0.8em;

    strong {
      font-size: 1.5em;
      margin: 0 0 0 10px;
    }
  }

  &__gallery {
    width: 100%;
    height: 500px;
    margin: 0 0 50px 0;
    display: flex;
    justify-content: space-between;
  }

  &__photos {
    --width: 9;
    height: 100%;
    text-align: center;
    @include media-breakpoint-only(xs) {
      --width: 12;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__thumbs {
    --width: 2;
    height: 100%;
    @include media-breakpoint-only(xs) {
      display: none;
    }

    .swiper-slide {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      opacity: 0.4;

      &.swiper-slide-thumb-active {
        opacity: 1;
      }
    }
  }

  &__checker.ui.form {
		background: $color-white;
		width: 100%;
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

		input.prompt {
			background: $color-lightgrey !important;
			border-radius: 20px 0 0 20px;
			border: 1px solid transparent;
			color: $color-black;
			max-width: calc(100% - 60px);
		}

		button {
			background-color: $color-blue;
			border: none;
			height: 38px;
			width: 50px;
			border-radius: 0 20px 20px 0;
			display: inline-block;
			background-size: 20px;
      background-position: 14px;
      background-repeat: no-repeat;
      margin: 0;
      padding: 0;
		}

		.results {
			top: calc(100% + 5px);
			border: 1px solid #dadada;
			padding: 4px;
		}
	}
}

.product-info {
  display: flex;
  align-items: center;

  > .icon {
    color: #525252;
    font-size: 25px;
    margin: 0 20px 0 0;
    overflow: visible;
  }

  strong {
    font-weight: 900;
  }

  &__shipping {
    color: #00b3e3;
    font-weight: 500;
  }
}

.select-appliance {
  &__segment {
    padding: 10px 15px;
    align-items: center;
    justify-content: center;

    &:nth-child(even) {
      background: $color-lightgrey;
    }

    strong {
      font-weight: 500;
    }
  }

  &__button {
    margin: 20px 0 0 0;
    text-align: center;
  }

  img {
    max-width: 100px;
    max-height: 100px;
    height: auto;
    width: auto;
    padding: 10px;
  }
}

.product-substitute {
  display: flex !important;
  padding: 20px;
  border: 1px solid #d0d0d0;
  height: 100%;
  @include media-breakpoint-down(xs) {
    flex-direction: column;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-lightgrey;
    padding: 10px;
    @include media-breakpoint-up(sm) {
      margin-right: 20px;
      width: 30%;
    }
    @include media-breakpoint-down(xs) {
      margin-bottom: 20px;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  & .product__code {
    flex: 1;
  }

  &__footer {
    text-align: right;
    background: $color-lightgrey;
    padding: 15px;
    margin-top: 30px;

    i {
      color: yellowgreen;
      font-size: 18px;
      line-height: 1;
    }
  }

  &__price {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.5;
    margin-top: 10px;
  }
}

#products {
  justify-content: flex-start;
}