.article-row {
    display: flex;
    flex-wrap: wrap;
    background: $color-lightgrey;
    &__column {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        &:first-of-type {
            padding: 20px;
        }
        .ui.header {
            font-size: 20px;
            background: transparent;
        }
        p {
            margin-top: 10px !important;
        }
    }
    &__product {
        display: flex;
        height: 100%;
        &-image {
            background: $color-white;
            align-items: center;
            justify-content: center;
        }
        &-content {
            justify-content: center;
            a {
                font-size: 20px;
                line-height: 1.5;
                margin-bottom: 20px;
                color: $color-blue;
                font-weight: bold;
            }
        }
        > div {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 10px;
        }
    }
}
