.title {
	&--lg {
		font-size: 40px;
		font-weight: 700;
		@include media-breakpoint-down(sm) {
			font-size: 32px;
		}
	}
	&--md {
		font-size: 30px;
		font-weight: 700;
		@include media-breakpoint-down(sm) {
			font-size: 25px;
		}
	}
	&--sm {
		font-size: 20px;
		font-weight: 700;
		text-transform: uppercase;
		@include media-breakpoint-down(sm) {
			font-size: 18px;
		}
	}
}

.sub-header {
	font-size: 16px;
	font-weight: 100;
	opacity: 0.6;
}

.ui .header {
	background: inherit;
	border: 0;
}
