#product-category-select {
    padding: 15px 25px;
    border: 0;
    font-size: 18px;
    outline: none;
    background: $color-lightgrey;
    display: block;
    width: 100%;
    border-radius: 30px;
    -webkit-appearance: none;
	-moz-appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, $color-lightblue 50%), linear-gradient(135deg, $color-lightblue 50%, transparent 50%);
    background-position: calc(100% - 25px) calc(1em + 4px), calc(100% - 20px) calc(1em + 4px);
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
}

.category-tabs {
	$this: &;
	flex-wrap: wrap;
	@include reset-list;
	&__tab {
		text-align: center;
		position: relative;
		padding: 1.5em 1em;
		cursor: pointer;
		@include media-breakpoint-only(xs) {
			padding: 1.5em 0;
		}
		&.active {
			#{$this}__icon {
				opacity: 1;
				&:after {
					background: $color-lightblue;
				}
			}
			#{$this}__title {
				color: $color-lightblue;
			}
		}
	}
	&__title {
		color: #707070;
		font-weight: 400;
		font-size: 15px;
		margin: 15px 0 0 0;
		@include media-breakpoint-only(xs) {
			font-size: 12px;
			margin: 10px 0 0 0;
		}
	}
	&__icon {
		position: relative;
		padding: 0 0 20px 0;
		opacity: 0.5;
		&:after {
			content: "";
			height: 5px;
			width: 55px;
			display: block;
			background: #c6c6c6;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	&__nameplate-pic {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
	.icon {
		width: 50px;
		height: 50px;
		@include media-breakpoint-only(xs) {
			width: 40px;
			height: 40px;
		}
	}
}

.category-slider {
	&__thumbnail {
		text-align: center;
		margin: 15px 10px;
		img {
			max-width: 160px;
			max-height: 160px;
			width: auto;
			height: auto;
		}
	}
	&__details {
		background: $color-lightgrey;
		padding: 15px 10px;
		border-radius: 10px;
		text-align: center;
	}
	&__title {
		margin: 0 0 25px 0;
		font-weight: 500;
		font-size: 16px;
	}
}

#tabs-nameplates {
	margin-top: 50px;
	margin-bottom: 50px;
	.column {
		--width: 6;
		@include media-breakpoint-down(sm) {
			--width: 12;
			justify-content: center;
		}
	}
	.category-tabs__tab {
		flex: 0 0 25%;
	}
}

#tabs-products {
	.column {
		--width: 12;
	}
	.category-tabs__tab {
		flex: 1;
		@include media-breakpoint-down(sm) {
			flex: 1 0 25%;
		}
	}
	.swiper-pagination {
		position: static !important;
		margin: 50px 0 0 0;
	}
	.swiper-container {
		margin-top: 50px;
		padding: 0 50px;
	}
	.swiper-button-black {
		background: rgba(0,0,0,0.2);
		height: 120px;
		width: 40px;
		border-radius: 5px;
		transform: translateY(-75px);
	}
	.swiper-button-prev {
		left: 0;
	}
	.swiper-button-next {
		right: 0;
	}
}


@include media-breakpoint-up(md) {
	#product-category-select {
		display: none;
	}
	.category-tabs {
		display: flex !important;
	}
	.category-slider {
		display: block !important;
	}
}
@include media-breakpoint-down(sm) {
	#product-category-select {
		display: block;
	}
	.category-tabs,
	.category-slider {
		display: none !important;
	}
}