.ui.fluid.floating.dropdown.labeled.search.button.address-book-select {
  background: $color-lightgrey !important;
  padding: 20px !important;
}

.ui.steps {
  border-radius: 0 !important;
}

.ui.steps .step.active .title {
  color: $color-blue;
}

.ui.ordered.steps .step.active:before,
.ui.steps .active.step .icon {
  color: $color-blue;
}

.ui.ordered.steps .step.completed:before,
.ui.steps .step.completed > .icon:before {
  color: $color-accent;
}

.widget {
  .contact__icon {
    margin: 0 20px 0 0;
  }
}

.checkout {
  &__controls {
    .column {
      --width: 6;
    }
  }
}
