// colors

$color-white: #fff;
$color-lightgrey: #f6f6f6;
$color-black: #000;
$color-lightblue: #1d81bc;
$color-blue: #1327a0;
$color-darkblue: #0b175c;
$color-accent: #00b3e3;
$color-red: #d93b30;

$grid-breakpoints: (
	// Mobile
		xs: 0,
	// Tablet
		sm: 768px,
	// Small Monitor
		md: 992px,
	// Large Monitor
		lg: 1200px
);