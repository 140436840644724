.panel {
	background: $color-black;
	display: flex;
	flex-wrap: wrap;
	padding: 24px;
	align-items: center;
	width: 100%;
	@include media-breakpoint-only(xs) {
		justify-content: center;
	}
	&__menu {
		@include reset-list;
		display: flex;
		flex: auto;
		flex-wrap: wrap;
		@include media-breakpoint-only(xs) {
			justify-content: center;
		}
		a {
			color: $color-white;
			display: table-cell;
			position: relative;
			border-spacing: 0;
			vertical-align: middle;
			padding: 11px;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			max-height: 38px;
			font-weight: 500;
			text-transform: uppercase;
			text-align: center;
			font-size: 14px;
			position: relative;
			margin: 0 0 10px 0;
			&:after {
				content: " ";
				display: block;
				bottom: 0px;
				height: 2px;
				background: $color-white;
				width: 20px;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				opacity: 0;
				visibility: hidden;
				transition: 0.3s all ease-in-out;
			}
			&:hover:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	.button {
		@include media-breakpoint-only(xs) {
			margin: 20px 0 0 0;
		}
	}
}
