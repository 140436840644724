.breadcrumbs {
    color: lighten($color-black, 50);
	display: flex;
    margin: 20px 0;
    flex-wrap: wrap;
    width: 100%;
    &__item {
        margin: 0 11px;
        .active {
            color: lighten($color-black, 60)
        }
    }
    &__divider {
        color: lighten($color-black, 70);
    }
}