.product-card {
	--width: 4;
	max-width: 350px;
	padding: 15px;
	border-right: 1px solid;
	border-bottom: 1px solid;
	border-color: #dededf;
	position: relative;
	display: flex;
	flex-direction: column;
	@include media-breakpoint-only(xs) {
		--width: 12;
	}
	&:nth-child(3n) {
		border-right: none !important;
	}
	&__thumb {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 0 20px 0;
		flex: auto;
		img {
			max-width: 75%;
			max-height: 75%;
		}
	}
	&__title {
		font-size: 16px;
		font-weight: 500;
		margin: 0 0 20px 0;
	}
	&__price {
		font-size: 18px;
		font-weight: 700;
	}
	&__shipping {
		color: $color-accent;
		font-weight: 500;
		.icon {
			color: #fff;
			background: $color-accent;
			height: 25px;
			width: 25px;
			line-height: 25px;
			border-radius: 30px;
		}
	}
	.container {
		justify-content: space-between;
	}
	a {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 2;
		text-indent: -9999px;
		transition: 0.3s ease-in-out all;
		&:hover {
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		}
	}
}
