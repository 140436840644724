.footer {
	border-top: 1px solid #d0d0d0;
	background-color: #f5f5f5;
	margin: 40px 0 0 0;
	.container:first-child {
		padding: 30px 20px;
	}
	&__title {
		font-size: 14px;
		color: #212428;
		font-weight: 500;
		text-transform: uppercase;
	}
	&__menu,
	&__menu > ul {
		@include reset-list;
		li {
			padding-bottom: 2px;
		}
		a {
			font-size: 12px;
			color: #212428;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.column {
		--width: 2.4;
		padding: 10px;
		min-width: 160px;
		@include media-breakpoint-only(xs) {
			--width: 4;
		}
	}
	&__bottom {
		background: #525252;
		min-height: 60px;
		&-menu, &-menu > ul {
			@include reset-list;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			@include media-breakpoint-only(xs) {
				width: 100%;
				justify-content: center;
			}
			a {
				font-size: 12px;
				color: $color-white;
				font-weight: 500;
				text-transform: uppercase;
				padding: 0 12px;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.icon {
			font-size: 30px;
			line-height: 1;
			margin: 0;
			overflow: unset;
			text-decoration: none;
		}
	}
	&__social-menu {
		margin-left: auto;
		@include media-breakpoint-only(xs) {
			margin-right: auto;
			margin-top: 20px;
		}
		.container {
			@include media-breakpoint-only(xs) {
				justify-content: center;
			}
		}
	}
}
