body {
	margin: 0;
	font-weight: 300;
}

body.pushable .pusher {
	background-color: $color-white;
}

body,
textarea,
input,
select,
textarea,
button,
h1,
h2,
h3,
h4,
h5 {
	font-family: "Poppins", sans-serif !important;
	font-display: swap;
}

a {
	text-decoration: none;
}

img {
	max-width: 100%;
	max-height: 100%;
}

.pusher {
	display: flex;
	flex-direction: column;
	> .container {
		flex: auto;
		flex-direction: column;
		justify-content: start;
	}
}

.align-center {
	text-align: center;
}

.align-right {
	float: right;
}

.controls {
	display: flex;
	flex-wrap: wrap;
}

i.icon {
	height: auto !important;
}

.ui.message:first-child {
	margin-top: 1em !important;
}

.container {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	max-width: 1440px;
	width: 100%;
	justify-content: center;
	&__header {
		width: 100%;
		margin: 40px 0;
		padding: 0 10px;
		&--columns {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
		}
	}
	&--narrow {
		max-width: 1000px;
	}
}

.column {
	--columns: 12; /* Number of columns in the grid system */
	flex-basis: calc(var(--width) / var(--columns) * 100%);
	&--wide {
		flex: auto;
	}
}

.color {
	&--blue {
		color: $color-blue !important;
	}
}

.main-content {
	display: flex;
	width: 100%;
	@include media-breakpoint-down(sm) {
		flex-wrap: wrap;
	}
	&--narrow {
		max-width: 1200px;
		margin: auto;
	}
	&__content {
      flex: 0 0 calc(70% - 20px);
      max-width: calc(70% - 20px);
      margin: 20px 0;

      .widget:first-child {
        margin-top: 0;
      }

      @include media-breakpoint-down(sm) {
        flex: auto;
        max-width: unset;
        width: 100%;
      }
    }
	&__sidebar {
		flex: 0 0 30%;
		max-width: 30%;
		@include media-breakpoint-down(sm) {
			flex: auto;
			max-width: unset;
			width: 100%;
		}
		&--left {
			margin-right: 20px;
			@include media-breakpoint-down(sm) {
				margin: 20px 0;
			}
		}
		&--right {
			margin-left: 20px;
			@include media-breakpoint-down(sm) {
				margin: 20px 0;
			}
		}
	}
	&__segment {
		padding: 20px;
		width: 100%;
		margin: 0 0 20px 0;
		border: 1px solid $color-lightgrey;
	}
}

.cookies {
	display: none;
    position: fixed;
    right: 20px;
    bottom: 20px;
    padding: 20px;
    background: white;
    color: #707070;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100vw;
    max-width: 380px;
    z-index: 999;

	&-title {
		font-size: 16px;
		color: #707070;
		margin-bottom: 10px;
	}

	&-actions {
		margin: 5px -5px 0;

		a {
			background: whitesmoke;
			padding: 5px 15px;
			border-radius: 20px;
			display: inline-block;
			margin: 5px;
			color: #262626;
			transition: all 100ms;
			cursor: pointer;

			&:hover,
			&#cookies-consent {
				background: $color-blue;
				color: $color-white;
			}
		}
	}
}

// screen readers
.sr-only {
	position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
}
