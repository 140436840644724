.section {
	$this: &;
	padding: 40px 10px;
	width: 100%;
	&.section-grey {
		background: $color-lightgrey;
	}
	&.section-blue {
		background: #007cc2;
		color: $color-white;
	}
	&__title {
		font-size: 40px;
		font-weight: 700;
		text-align: center;
		max-width: 800px;
		margin: 0 auto 30px auto;
		@include media-breakpoint-down(sm) {
			font-size: 32px;
		}
	}
	&__subtitle {
		text-align: center;
		font-size: 18px;
		line-height: 1.4285em;
		font-weight: 500;
		width: 100%;
	}
	&__row:nth-child(odd) {
		#{$this}__content {
			flex-direction: row-reverse;
			text-align: right;
		}
	}
	&__content {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	&__image {
		flex: 1;
		text-align: center;
		padding: 30px;
		img {
			max-height: 250px;
		}
	}

	&__desc {
		flex: 1;
		padding: 30px;
		box-sizing: border-box;
	}
}

.accordion {
	.title {
		background: $color-lightgrey;
	}
	.title.active {
		font-weight: bold;
	}
	.content p {
		padding: 10px 20px;
	}
	.bitbag-frequently-asked-question {
		margin-bottom: 10px;
	}
}