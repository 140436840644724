.search {
	text-align: center;
	&__form {
		display: inline-flex;
		border-radius: 50px;
		overflow: hidden;
		height: 65px;
		min-width: 850px;
		margin: 0 auto 50px auto;
		@include media-breakpoint-down(sm) {
			min-width: auto;
			width: 100%;
		}
		.results {
			max-width: calc(100% - 85px);
			top: 110%;
			box-shadow: 0 0 0 5px $color-white;
			border-radius: 20px;
		}

		&.focus {
			overflow: unset;
			position: relative;
		}
	}
	input {
		padding: 15px 25px;
		border-radius: 50px 0 0 50px;
		transition: all 200ms;
		background: $color-lightgrey;
		border: 0;
		font-size: 18px;
		color: #525252;
		line-height: 65px;
		flex: auto;
		outline: none;
	}
	&__button {
		width: 85px;
		height: 65px;
		margin: 0;
		border: 0;
		background-size: 20px;
		border-radius: 0 50px 50px 0 !important;
		background-position: center;
		background-repeat: no-repeat;
		margin: 0;
		cursor: pointer;
		@include media-breakpoint-only(xs) {
			display: none;
		}
	}
}

// styles for the default semantic-ui search component
.results {
	position: absolute;
	top: 100%;
	width: 100%;
	background: $color-white;
	border: 5px solid $color-lightgrey;
	z-index: 998;
	border-radius: 5px;
	&:empty {
		display: none;
	}
	.message {
		padding: 8px 15px;
		.header {
			background: none;
			border: none;
		}
		div {
			padding: 4px 0;
			text-align: left;
		}
	}
	.result {
		cursor: pointer;
		color: $color-black;
		&:hover {
			color: $color-blue;
		}
		&:not(:last-of-type) {
			.content {
				border-bottom: 1px solid $color-lightgrey;
			}
		}
	}
	.content {
		text-align: left;
		padding: 8px 15px;
	}
	.model {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.title {
			color: #7f7f7f;
			margin: 0;
		}
	}
}

@include media-breakpoint-down(sm) {
	#tabs-nameplates .category-tabs {
		display: grid !important;
		grid-template-columns: 1fr 1fr;
		margin-bottom: 25px;

		&__tab {
			display: flex;
			align-items: center;
			padding: 5px;

			&.active {
				background: rgba(0,0,0,0.02);
				border-radius: 6px;
				font-weight: bold;
			}
		}

		&__icon {
			padding: 5px;

			&:after {
				display: none;
			}
		}

		&__title {
			margin: 0;
			text-align: left;
		}
	}
}
