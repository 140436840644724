.contact-info {
	margin-bottom: 50px;
	.column {
		--width: 6;
		max-width: 500px;
		@include media-breakpoint-down(sm) {
			--width: 12;
			margin: 20px auto 0 auto;
			justify-content: center;
			text-align: center;
		}
	}
}

.contact {
	display: flex;
	padding: 0 30px;
	align-items: center;
	box-sizing: border-box;
	@include media-breakpoint-down(sm) {
		flex-wrap: wrap;
	}
	.icon {
		width: 100px;
		height: 100px;
		margin: 0 25px 0 0;
		@include media-breakpoint-down(sm) {
			width: 80px;
			height: 80px;
			margin: 20px 0;
		}
	}
}
