.brands-list {
	ul {
		display: flex;
		flex-wrap: wrap;
		@include reset-list;
		justify-content: center;
		margin: auto;
	}
	li {
		border: 1px solid $color-lightgrey;
		box-sizing: border-box;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 5px;
	}
}
