.login-form {
	.column {
		--width: 6;
		padding: 10px;
		text-align: center;
		@include media-breakpoint-down(sm) {
			--width: 12;
		}
	}
	form {
		max-width: 500px !important;
		margin: auto;
	}
	.field {
		text-align: left;
		margin: 1.5em 0 !important;
	}
}
