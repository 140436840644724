.status {
	padding: 5px 20px;
	display: inline-block;
	margin: 5px;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 500;
	border: 1px solid;
	&--red {
		border-color: $color-red;
		color: $color-red;
	}
	&--blue {
		border-color: $color-accent;
		color: $color-accent;
	}
}
