.header {
	background-color: $color-white;
	border-bottom: 1px solid #dadada;
	.container {
		align-items: center;
	}
	&__nav {
		flex: 2;
		> ul {
			@include reset-list;
			display: flex;
			> li {
				.nav-item {
					padding: 0 11px;
					display: table-cell;
					position: relative;
					height: 64px;
					padding-top: 1px;
					font-weight: 500;
					font-size: 14px;
					color: #363636;
					line-height: 18px;
					vertical-align: middle;
					text-transform: uppercase;
					&:after {
						position: absolute;
						left: 1px;
						right: 1px;
						bottom: 0;
						z-index: 1;
						height: 4px;
						content: "";
						margin: 0 11px;
						background-color: $color-black;
						transform: scaleX(0);
						transition: transform 0.3s;
						@include media-breakpoint-down(sm) {
							display: none;
						}
					}
				}
				&.active {
					.nav-item {
						color: $color-blue;
						&:after {
							height: 4px;
							background-color: $color-black;
							transform: scaleX(1);
						}
					}
				}
			}
		}
		> a {
			padding: 0 11px;
			display: table-cell;
			position: relative;
			height: 64px;
			padding-top: 1px;
			font-weight: 500;
			font-size: 14px;
			color: #363636;
			line-height: 18px;
			vertical-align: middle;
			text-transform: uppercase;
		}
	}
	&__nav--left {
		@include media-breakpoint-down(sm) {
			position: absolute;
			background: $color-white;
			flex-direction: column;
			display: flex;
			top: 0;
			z-index: 5;
			left: 0;
			max-width: 300px;
			z-index: 666;
			transition: 0.4s ease-in-out;
			transform: translateX(-100%);
			line-height: 1;
			opacity: 0;
			height: 100vh;
			padding: 80px 20px 20px 20px;
		}
		ul {
			@include media-breakpoint-down(sm) {
				flex-direction: column;
				z-index: 704;
				overflow-y: auto;
			}
		}
		&.active {
			opacity: 1;
			transform: translateX(0);
		}
	}
	&__nav--right {
		display: flex;
		justify-content: flex-end;
		li {
			margin-left: 16px;
			position: relative;
		}
		@include media-breakpoint-down(sm) {
			order: 2;
		}
	}
	&__logo {
		display: flex;
		justify-content: center;
		flex: 0 0 auto;
		img {
			height: 25px;
			width: auto;
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
			flex: auto;
			order: 3;
			height: 64px;
			align-items: center;
			border-top: 1px solid #dadada;
		}
	}
	.icon {
		width: 20px;
		height: 20px;
	}
	&__dropdown {
		background: $color-white;
		position: absolute;
		right: -39px;
		padding: 15px;
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
		top: 65px;
		min-width: 160px;
		z-index: 666;
		visibility: hidden;
		opacity: 0;
		transition: 0.3s ease-in-out all;
		a {
			margin-bottom: 9px;
			font-size: 14px;
			color: $color-black;
			display: block;
			font-weight: 500;
			&:hover {
				color: $color-blue;
			}
		}
	}
	&__menu {
		visibility: hidden;
		opacity: 0;
		background: $color-white;
		border-bottom: 1px solid #dadada;
		z-index: 666;
		position: absolute;
		left: 0;
		right: 0;
		top: 65px;
		padding: 30px;
		transition: 0.3s ease-in-out all;
		@include media-breakpoint-down(sm) {
			visibility: visible;
			opacity: 1;
			position: static;
			border: 0;
			padding: 0 30px;
			.container {
				align-items: start;
			}
		}
		.column {
			--width: 3;
			@include media-breakpoint-down(sm) {
				--width: 12;
				margin: 20px 0 0 0;
			}
		}
		ul {
			@include reset-list;
		}
		a {
			margin-bottom: 9px;
			font-size: 14px;
			color: $color-black;
			display: block;
			&:hover {
				color: $color-blue;
				text-decoration: underline;
			}
		}
		&__title {
			text-transform: uppercase;
			font-weight: 500;
			margin-bottom: 9px;
			font-size: 16px;
			color: $color-black;
			margin: 0 0 10px 0;
			line-height: 20px;
		}
	}
	&__mobile-menu {
		position: relative;
		z-index: 678;
		display: none;
		@include media-breakpoint-down(sm) {
			display: block;
		}
	}
	.title {
		text-transform: uppercase;
		font-weight: 500;
		margin-bottom: 9px;
		font-size: 16px;
		color: $color-black;
		margin: 0 0 10px 0;
		line-height: 20px;
	}
	&__search.ui.form {
		display: none;
		position: absolute !important;
		background: $color-white;
		max-width: calc(100vw - 60px);
		top: 0;
		right: 0;
		width: 300px;
		height: 100%;
		z-index: 2;

		&.active {
			display: flex !important;
			align-items: center;
			justify-content: center;
		}

		input.prompt {
			background: $color-lightgrey !important;
			border-radius: 20px 0 0 20px;
			border: 1px solid transparent;
			color: $color-black;
			max-width: calc(100% - 60px);
		}

		button.icon {
			background-color: $color-blue;
			border: none;
			height: 38px;
			width: 50px;
			border-radius: 0 20px 20px 0;
			display: inline-block;
			background-size: 20px;
			background-position: 14px;
		}

		.results {
			top: calc(100% - 5px);
			border: 1px solid #dadada;
			padding: 4px;
		}
	}
}

.active > .header__menu {
	visibility: visible;
	opacity: 1;
}

.active > .header__dropdown {
	visibility: visible;
	opacity: 1;
}

.cart {
	position: relative;
	&__counter {
		background: $color-red;
		color: $color-white;
		text-align: center;
		height: 15px;
		min-width: 15px;
		line-height: 15px;
		font-size: 10px;
		border-radius: 20px;
		position: absolute;
		top: 10px;
		right: 0;
		font-weight: 500;
	}
	&__product-list {
		margin: 0 0 20px 0;
	}
	&__product {
		padding: 5px 0;
		border-bottom: 1px solid #d0d0d0;
		font-size: 14px;
		strong {
			font-weight: 500;
		}
	}
	&__subtotal {
		padding: 10px 0;
		font-size: 15px;
		font-weight: 500;
		margin: 0 0 20px 0;
	}
}

#toggle {
	width: 30px;
	height: 22px;
	position: relative;
	transform: rotate(0deg);
	transition: 0.5s ease-in-out;
	display: inline-block;
	vertical-align: middle;
	margin: 0 11px;
	@include media-breakpoint-down(sm) {
		order: 1;
	}
	span {
		display: block;
		position: absolute;
		height: 3px;
		width: 100%;
		background: $color-black;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: 0.25s ease-in-out;
		&:nth-child(1) {
			top: 0px;
		}
		&:nth-child(2),
		&:nth-child(3) {
			top: 9px;
		}
		&:nth-child(2),
		&:nth-child(3) {
			top: 9px;
		}
		&:nth-child(4) {
			top: 18px;
		}
	}
	&.active {
		span {
			&:nth-child(1) {
				top: 18px;
				width: 0%;
				left: 50%;
			}
			&:nth-child(2) {
				transform: rotate(45deg);
			}
			&:nth-child(3) {
				transform: rotate(-45deg);
			}
			&:nth-child(4) {
				top: 18px;
				width: 0%;
				left: 50%;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.pusher.mobile-menu__overlay {
		overflow: hidden;
		height: 100vh;
	}

	.pusher:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.5);
		opacity: 0;
		transition: 0.3s all ease-in-out;
		visibility: none;
		z-index: -1;
	}

	.pusher.mobile-menu__overlay:before {
		opacity: 1;
		visibility: visible;
		z-index: 10;
	}
}
