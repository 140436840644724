.hero {
	background: #ccc;
	position: relative;
	&__cover {
		position: absolute;
		width: 100%;
		height: 100%;
		img {
			@include cover-image;
		}
	}
	&__content {
		position: relative;
		z-index: 2;
		color: $color-white;
		min-height: 600px;
		align-items: center;
		padding: 45px 90px;
		box-sizing: border-box;
		@include media-breakpoint-down(sm) {
			padding: 45px 20px;
			min-height: auto;
		}
	}
	.column {
		--width: 6;
		box-sizing: border-box;
		@include media-breakpoint-down(sm) {
			--width: 12;
		}
	}
	&__title {
		color: $color-white;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 40px;
	}
	&__overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(90deg, rgba(0, 0, 0, 0.4), transparent);
		z-index: 1;
	}
}
