.button {
	background-color: transparent;
	color: $color-black;
	border: 1px solid $color-black;
	padding: 12px 34px;
	display: inline-block;
	border-radius: 50px;
	font-weight: 500;
	transition: 0.3s ease-in-out all;
	font-size: 14px;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	text-indent: 0.15em;
	text-align: center;
	cursor: pointer;
	margin: 5px;
	&--wide {
		width: 100%;
	}
	&:hover {
		background-color: $color-black;
		border-color: $color-black;
		color: $color-white;
	}
	&.button--white-outline {
		color: $color-white;
		border-color: $color-white;
		&:hover {
			background-color: $color-white;
			border-color: $color-white;
			color: $color-black;
		}
	}
	&.button--blue {
		background-color: $color-blue;
		border-color: $color-blue;
		color: $color-white;
		&:hover {
			background-color: darken($color-blue, 15);
			border-color: darken($color-blue, 15);
			color: $color-white;
		}
		&-outline {
			color: $color-blue;
			border-color: $color-blue;
			&:hover {
				background-color: $color-blue;
				border-color: $color-blue;
				color: $color-white;
			}
		}
	}
	&.button--grey {
		background-color: $color-lightgrey;
		border-color: $color-lightgrey;
		color: $color-black;
		&:hover {
			background-color: darken($color-lightgrey, 15);
			border-color: darken($color-lightgrey, 15);
			color: $color-black;
		}
		&-disabled{
			background-color: darken($color-lightgrey, 5);
			border-color: darken($color-lightgrey, 5);
			color: $color-black;
		}
	}
	&.button--red {
		color: $color-red;
		border-color: $color-red;
		&:hover {
			background-color: $color-red;
			border-color: $color-red;
			color: $color-white;
		}
	}
	&--sm {
		padding: 7px 14px !important;
		font-size: 12px !important;
	}
}
